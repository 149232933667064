import '../styles/search.scss';
import React from 'react';
import { ReactComponent as SearchIcon } from '../images/search-icon.svg';
import classNames from 'classnames';

export interface searchProps {
    getSearchInput: (searchString: string) => void;
    clearSearchInput: (searchString: string) => void;
    searchTerm: string;
    placeholder?: string;
    customClass?: string;
}

export default function Search(props: searchProps) {
    return (
        <div className={classNames('search-container', props.customClass)}>
            <div className="search-box">
                <SearchIcon height={'18px'} fill={'#6c757d'} />
                <input
                    onChange={(e) => {
                        return props.getSearchInput(e.target.value);
                    }}
                    type="text"
                    placeholder={props.placeholder ? props.placeholder : ''}
                    value={props.searchTerm}
                    className="search-input"
                ></input>
                <div
                    className={classNames(
                        'clear-btn',
                        props.searchTerm.length > 0 ? 'visible' : '',
                    )}
                    onClick={() => {
                        return props.clearSearchInput('');
                    }}
                >
                    Clear
                </div>
            </div>
        </div>
    );
}
