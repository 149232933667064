import React, { Suspense, lazy } from 'react';
import AdfLogs from '../landing/AdfLogs';
import DashboardSkeleton from '../css-skeleton-loader/DashboardSkeleton';
import DataIngestionClient from '../landing/DataIngestionClient';
import ListViewSkeleton from '../css-skeleton-loader/ListViewSkeleton';
import PreprocessingLogs from '../landing/PreprocessingLogs';

const Dashboard = lazy(() => {
    return import('../dashboard/Dashboard');
});
const AllLoss = lazy(() => {
    return import('../loss-runs/AllLoss');
});
const Renamer = lazy(() => {
    return import('../landing/Renamer');
});
const DataIngestion = lazy(() => {
    return import('../landing/DataIngestion');
});
const Logs = lazy(() => {
    return import('../landing/Logs');
});
const Account = lazy(() => {
    return import('../master-data/account-master/Account');
});
const Carrier = lazy(() => {
    return import('../master-data/carrier-master/Carrier');
});
const LineOfBusiness = lazy(() => {
    return import('../master-data/lob-master/LineOfBusiness');
});
const LookupInsured = lazy(() => {
    return import('../master-data/lookup-insured/LookupInsured');
});
const PolicySchedule = lazy(() => {
    return import('../master-data/PolicySchedule');
});
const Setting = lazy(() => {
    return import('../setting/Setting');
});
const Preprocessing = lazy(() => {
    return import('../setting/pre-processing/Preprocessing');
});
const PdfTransform = lazy(() => {
    return import('../setting/post-processing/loss-runs-pdf/PdfTransform');
});
const ExcelTransform = lazy(() => {
    return import('../setting/post-processing/loss-runs-excel/ExcelTransform');
});

const LazyComponents = {
    Dashboard: () => {
        return (
            <Suspense fallback={<DashboardSkeleton />}>
                <Dashboard />
            </Suspense>
        );
    },
    AllLoss: () => {
        return (
            <Suspense fallback={null}>
                <AllLoss />
            </Suspense>
        );
    },
    Renamer: () => {
        return (
            <Suspense fallback={null}>
                <Renamer />
            </Suspense>
        );
    },
    DataIngestion: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <DataIngestion />
            </Suspense>
        );
    },
    DataIngestionClient: () => {
        return (
            <Suspense fallback={<span>Loading...</span>}>
                <DataIngestionClient />
            </Suspense>
        );
    },
    Logs: () => {
        return (
            <Suspense
                fallback={
                    <div className="scrollable-container h-auto overflow-auto">
                        <ListViewSkeleton />
                    </div>
                }
            >
                <Logs />
            </Suspense>
        );
    },
    PreprocessingLogs: () => {
        return (
            <Suspense
                fallback={
                    <div className="scrollable-container h-auto overflow-auto">
                        <ListViewSkeleton />
                    </div>
                }
            >
                <PreprocessingLogs />
            </Suspense>
        );
    },
    AdfLogs: () => {
        return (
            <Suspense
                fallback={
                    <div className="scrollable-container h-auto overflow-auto">
                        <ListViewSkeleton />
                    </div>
                }
            >
                <AdfLogs />
            </Suspense>
        );
    },
    Account: () => {
        return (
            <Suspense
                fallback={
                    <div className="scrollable-container h-auto overflow-auto">
                        <ListViewSkeleton />
                    </div>
                }
            >
                <Account />
            </Suspense>
        );
    },
    Carrier: () => {
        return (
            <Suspense
                fallback={
                    <div className="scrollable-container h-auto overflow-auto">
                        <ListViewSkeleton />
                    </div>
                }
            >
                <Carrier />
            </Suspense>
        );
    },
    LineOfBusiness: () => {
        return (
            <Suspense
                fallback={
                    <div className="scrollable-container h-auto overflow-auto">
                        <ListViewSkeleton />
                    </div>
                }
            >
                <LineOfBusiness />
            </Suspense>
        );
    },
    LookupInsured: () => {
        return (
            <Suspense
                fallback={
                    <div className="scrollable-container h-auto overflow-auto">
                        <ListViewSkeleton />
                    </div>
                }
            >
                <LookupInsured />
            </Suspense>
        );
    },
    PolicySchedule: () => {
        return (
            <Suspense
                fallback={
                    <div className="scrollable-container h-auto overflow-auto">
                        <ListViewSkeleton />
                    </div>
                }
            >
                <PolicySchedule />
            </Suspense>
        );
    },
    Setting: () => {
        return (
            <Suspense
                fallback={
                    <div className="scrollable-container h-auto overflow-auto">
                        <ListViewSkeleton />
                    </div>
                }
            >
                <Setting />
            </Suspense>
        );
    },
    Preprocessing: () => {
        return (
            <Suspense
                fallback={
                    <div className="scrollable-container h-auto overflow-auto">
                        <ListViewSkeleton />
                    </div>
                }
            >
                <Preprocessing />
            </Suspense>
        );
    },
    PdfTransform: () => {
        return (
            <Suspense
                fallback={
                    <div className="scrollable-container h-auto overflow-auto">
                        <ListViewSkeleton />
                    </div>
                }
            >
                <PdfTransform />
            </Suspense>
        );
    },
    ExcelTransform: () => {
        return (
            <Suspense
                fallback={
                    <div className="scrollable-container h-auto overflow-auto">
                        <ListViewSkeleton />
                    </div>
                }
            >
                <ExcelTransform />
            </Suspense>
        );
    },
};

export default LazyComponents;
