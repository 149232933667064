import route from '../routes/routes.json';

export interface tabItemType {
    url: string;
    label: string;
    order: number;
}

export const masterDataTabItem: tabItemType[] = [
    {
        url: route['masterData']['account'],
        label: 'Account',
        order: 1,
    },
    {
        url: route['masterData']['carrier'],
        label: 'Carrier',
        order: 2,
    },
    {
        url: route['masterData']['lob'],
        label: 'Line Of Business',
        order: 3,
    },
    {
        url: route['masterData']['lkpInsured'],
        label: 'Lookup Insured',
        order: 4,
    },
    {
        url: route['masterData']['policySchedule'],
        label: 'Policy Schedule',
        order: 5,
    },
];

export const settingsTabItem: tabItemType[] = [
    {
        url: route['setting']['preprocessing'],
        label: 'Pre-Processing',
        order: 1,
    },
    {
        url: route['setting']['pdfTransform'],
        label: 'Post-Processing',
        order: 2,
    },
];

export const landingTabItem: tabItemType[] = [
    {
        url: route['landing']['renamer'],
        label: 'Renamer',
        order: 2,
    },
    {
        url: route['landing']['dataIngestion'],
        label: 'Data Ingestion',
        order: 1,
    },
    {
        url: route['landing']['preprocessingLogs'],
        label: 'Logs',
        order: 3,
    },
];

export const postprocessingTabItem: tabItemType[] = [
    {
        url: route['setting']['pdfTransform'],
        label: 'PDF Transform',
        order: 1,
    },
    {
        url: route['setting']['excelTransform'],
        label: 'Excel Transform',
        order: 2,
    },
];

export const logsTabItem: tabItemType[] = [
    {
        url: route['landing']['preprocessingLogs'],
        label: 'Preprocessing',
        order: 1,
    },
    // {
    //     Url: route['landing']['adfLogs'],
    //     Label: 'ADF',
    //     Order: 2,
    // },
];

export const lossRunsTabItem: tabItemType[] = [
    {
        url: route['lossRuns']['allLosses'],
        label: 'All Losses',
        order: 1,
    },
];
