export const ROOT_FOLDER = 'mango';
export const ROOT_FOLDER_CLIENT = 'client';
export const MAX_UPLOAD_LIMIT = 20;
export const TERM_YEAR = 20;
export const MASTER_DATA_PAGE_LIMIT = 50;
export const ZIP_DOWNLOAD_FILE_NAME_PREFIX = 'Renamed Loss Runs';
export const MAX_UPLAOD_ERROR = `Maximum of ${MAX_UPLOAD_LIMIT} files are allowed to upload at a time`;
export const SUPPORTED_FILE_FORAMTS = ['.pdf', '.xlsx', '.xls', '.csv'];
export const SUPPORTED_FILE_TYPES = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'text/csv',
    'application/vnd.ms-excel',
];
export const HELP_TEXT = `Allowed file formats: ${SUPPORTED_FILE_FORAMTS.join(',')}. Maximum file limit: ${MAX_UPLOAD_LIMIT}.`;
export const FILE_NOT_SUPPORTED_ERROR = `Allowed file formats: ${SUPPORTED_FILE_FORAMTS.join(', ')}`;

/* Radio button values */
export const RADIO_BUTTON_VALUES: { id: 'dashboard' | 'expiration'; label: string }[] = [
    { id: 'dashboard', label: 'Dashboard' },
    { id: 'expiration', label: 'Expiration' },
];

/* Radio button values transform */
export const RADIO_BUTTON_DETAILS_DIALOG: { id: 'view' | 'edit'; label: string }[] = [
    { id: 'view', label: 'View' },
    { id: 'edit', label: 'Edit' },
];

/* Transform pdf edit constants start*/
export const DISABLE_EDIT_COMMON_FIELDS = ['partitionKey', 'rowKey', 'fromDate'];
export const DISABLE_EDIT = [
    'columnsToBeExtracted',
    'metaDataFields',
    'mlProjectName',
].concat(DISABLE_EDIT_COMMON_FIELDS);

export const ENABLE_EDIT = [
    'active',
    'commonFields',
    'mergeCells',
    'runningPageChecker',
    'claimCountColumn',
    'modelName',
];
export const TRANSFORM_PDF_ADD_REQUIRED_FIELDS = ['modelName', 'commonFields'];
export const TRANSFORM_PDF_ADD_EXCLUDE = [
    'partitionKey',
    'rowKey',
    'metaDataFields',
    'mlProjectName',
];

export const DEFAULT_BOOL_OPTIONS = [
    { value: 'True', label: 'True' },
    { value: 'False', label: 'False' },
];

export const COLUMNS_TO_BE_MAPPED = [
    // { id: 'ValueMandatory', label: 'Value Mandatory' },
    { id: 'PdfColumnNames', label: 'Pdf Column Names' },
    // { id: 'ConditionalColumn', label: 'Conditional Column' },
    // { id: 'DependentColumn', label: 'Dependent Column' },
];
/* Transform pdf edit constants end*/

/* All Losses constants start*/
export const LOSS_TYPE_OPTIONS = [
    { value: 'dashboard', label: 'Dashboard' },
    { value: 'expiration', label: 'Expiration' },
];

export const DEFAULT_NO_DATA_TEXT = 'Click on GET button to load Loss Runs';
/* All Losses constants end*/

/* Transform Excel constants start*/
export const EXCEL_SUPPORTED_FILE_FORAMTS = ['.xlsx', '.xls', '.csv'];
export const EXCEL_FILE_NOT_SUPPORTED_ERROR = `Allowed file formats: ${EXCEL_SUPPORTED_FILE_FORAMTS.join(', ')}`;
export const TRANSFORM_EXCEL_DISABLE_EDIT = [
    'partitionKey',
    'rowKey',
    'metaDataFields',
    'columnsToBeExtracted',
    'carrierName',
];
export const TRANSFORM_EXCEL_ADD_EXCLUDE = [
    'partitionKey',
    'rowKey',
    'claimsSheetNumber',
    'headerRowNumber',
    'metaDataFields',
];
export const TRANSFORM_EXCEL_ADD_REQUIRED_FIELDS = ['carrierName'];
export const ALLOW_NUMBER_ONLY = /^[1-9][0-9]*$/;
/* Transform Excel constants end*/

/* Role based access constants start */
export const READER = 'READER';
export const EXTERNAL_USER = 'EXTERNAL_USER';
export const QA = 'QA';
export const DATA_CONTRIBUTOR = 'DATA_CONTRIBUTOR';
/* Role based access constants end */

/* Data ingestion client constants start*/
export const QA_SHEET_DOWNLOAD_NAME =
    'CharleeAI_AccordFormExtractionXgoZeal_Filestatus_Tracker.xlsx';
/* Data ingestion client constants end */

/* Looku insured */
export const LOOKUP_INSURED_DISABLE_EDIT = ['insuredNameKey'];

export const LOOKUP_INSURED_REQUIRED_FIELDS = ['keywords', 'insuredName'];

/* Master data */
export const MAX_NO_OF_CHARACTERS_SHORTNAME = 20;
export const MAX_NO_OF_CHARACTERS_LOB_SHORTNAME = 5;
export const LOB_SHORT_NAME_REGEX = /^[a-z]*$/;
export const SHORT_NAME_REGEX = /^[a-zA-Z0-9 ]{0,20}$/;
