import { MASTER_DATA_PAGE_LIMIT } from '../constants/constants';
import { getData } from './base';
import { preprocessingLogsType } from '../landing/PreprocessingLogs';

export const getPreprocessingLogs = async (pageNumber: number, search?: string) => {
    let endpoint = `v1/log/pre-processings?page_size=${MASTER_DATA_PAGE_LIMIT}&page_number=${pageNumber}`;
    if (search) {
        endpoint = `${endpoint}&search=${search}`;
    }
    const { data, error } = await getData(endpoint);
    const dataFormatted: {
        data: { [key: string]: preprocessingLogsType[] };
        total: number;
    } = {
        data: {},
        total: 0,
    };
    if (data && data.data) {
        Object.keys(data.data).forEach((key) => {
            dataFormatted.data[key] = data.data[key].map((eachData: any) => {
                return {
                    client: eachData['client'],
                    originalFileName: eachData['original_file_name'],
                    fileType: eachData['file_type'],
                    action: eachData['action'],
                    sourceContainer: eachData['source_folder_name'],
                    destinationContainer: eachData['destination_folder_name'],
                    inputFileName: eachData['input_file_name'],
                    outputFileName: eachData['output_file_name'],
                    description: eachData['description'],
                    processName: eachData['process_name'],
                    status: eachData['status'],
                    uploadedByEmail: eachData['uploaded_by_email'],
                    uploadedByName: eachData['uploaded_by_name'],
                    uploadedOn: eachData['uploaded_on'],
                    logGeneratedOn: eachData['time_generated'],
                } as preprocessingLogsType;
            });
        });
        dataFormatted.total = data.total ? data.total : 0;
    }

    return { data: dataFormatted, error };
};
