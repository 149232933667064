import React, { useCallback, useEffect, useState } from 'react';
import { MASTER_DATA_PAGE_LIMIT } from '../constants/constants';
import PreprocessingLogDetail from './PreprocessingLogDetail';
import Search from '../components/Search';
import ZealDocPagination from '../components/Pagination';
import { getPreprocessingLogs } from '../api/manageLogs';
import { toast } from 'react-toastify';

export interface preprocessingLogsType {
    uniqueIdentifier: string;
    client: string;
    originalFileName: string;
    fileType: string;
    action: string;
    sourceContainer: string;
    destinationContainer: string;
    inputFileName: string;
    outputFileName: string;
    description: string;
    processName: string;
    status: string;
    uploadedByEmail: string;
    uploadedByName: string;
    uploadedOn: string;
    logGeneratedOn: string;
}

function PreprocessingLogs() {
    const [preprocessingLogs, setPreprocessingLogs] = useState<{
        [key: string]: preprocessingLogsType[];
    }>({});
    const [totalLogs, setTotalLogs] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedLog, setSelectedLog] = useState<preprocessingLogsType[]>([]);
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);

    const getPreprocessingLogsApi = useCallback(
        async (pageNumber: number = 1, search: string = '') => {
            setIsLoading(true);
            try {
                const { data, error } = await getPreprocessingLogs(pageNumber, search);
                if (error) {
                    toast.error(error);
                } else {
                    setPreprocessingLogs(data.data);
                    setTotalLogs(data.total);
                }
            } finally {
                setIsLoading(false);
            }
        },
        [getPreprocessingLogs],
    );

    useEffect(() => {
        getPreprocessingLogsApi(currentPage, searchTerm);
    }, [getPreprocessingLogsApi, currentPage, searchTerm]);

    const getSearchInput = (input: string) => {
        setSearchTerm(input);
        // Reset to the first page for a new search
        setCurrentPage(1);
    };

    const clearSearchInput = () => {
        setSearchTerm('');
        // Reset to the first page after clearing search
        setCurrentPage(1);
    };

    const handleModalClose = () => {
        setSelectedLog([]);
        setShowDetailModal(false);
    };

    return (
        <div className="content-container logs-container">
            <Search
                placeholder="Search by id, or file name"
                searchTerm={searchTerm}
                getSearchInput={getSearchInput}
                clearSearchInput={clearSearchInput}
                customClass="logs-search"
            />
            <PreprocessingLogDetail
                data={selectedLog}
                showModal={showDetailModal}
                handleClose={handleModalClose}
            />
            <div className="scrollable-container gz-scroll logs-scrollable-container">
                <table
                    className="file-line-item-container"
                    style={{ tableLayout: 'fixed' }}
                >
                    <thead>
                        <tr className="file-header sticky">
                            <td className="file-header-item mw-auto w-auto">Id</td>
                            <td className="file-header-item">File Name</td>
                            <td className="file-header-item"></td>
                        </tr>
                    </thead>
                    {isLoading ? (
                        <tbody>
                            <tr>
                                <td className="file-body-item" colSpan={3}>
                                    <div className="inline-spinner"></div>
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            {Object.keys(preprocessingLogs).map((key, index) => {
                                return (
                                    <tr key={`${key}-id-${index}`} className="file-body">
                                        <td className="file-body-item mw-auto w-auto">
                                            {key}
                                        </td>
                                        <td className="file-body-item">
                                            {preprocessingLogs[key][0].originalFileName}
                                        </td>
                                        <td
                                            className="file-body-item file-body-item-clickable"
                                            onClick={() => {
                                                setSelectedLog(preprocessingLogs[key]);
                                                setShowDetailModal(true);
                                            }}
                                        >
                                            More detail
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    )}
                </table>
            </div>
            <ZealDocPagination
                totalRecords={totalLogs}
                recordLimitPerPage={MASTER_DATA_PAGE_LIMIT}
                siblingCount={1}
                currentPage={currentPage}
                tabStyle="project"
                onPageChange={(page) => {
                    return setCurrentPage(page);
                }}
            />
        </div>
    );
}

export default PreprocessingLogs;
