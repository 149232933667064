import './skeleton-common.scss';
import React from 'react';
import Skeleton from './Skeleton';

interface listViewSkeletonProps {
    count?: number;
}

function renderlistRows(count: number) {
    const rows = [];
    for (let r = 0; r < count; r++) {
        rows.push(
            <div className="skeleton-list-view-row" key={`skeleton-list-index-${r}`}>
                <Skeleton width={90 % r === 0 ? `${90 * 4}px` : `${90 * 3}px`} inline />
                <div className="skeleton-list-view-columns">
                    <Skeleton
                        count={5}
                        width="80px"
                        height="22px"
                        inline
                        className="skeleton-list-view-column"
                    />
                </div>
            </div>,
        );
    }
    return rows;
}

function ListViewSkeleton(props: listViewSkeletonProps) {
    return (
        <React.Fragment>
            <div className="skeleton-list-view-top-new-btn">
                <Skeleton width="90px" height="36px" />
                <Skeleton width="36px" height="36px" />
            </div>
            {renderlistRows(props.count ? props.count : 10)}
            <div className="skeleton-list-view-bottom-new-btn">
                <Skeleton width="90px" height="30px" />
            </div>
        </React.Fragment>
    );
}

export default ListViewSkeleton;
