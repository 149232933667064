import '../styles/pageLayout.scss';
import { DATA_CONTRIBUTOR, EXTERNAL_USER, QA, READER } from '../constants/constants';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { ReactComponent as AllLossIcon } from '../images/all-losses-icon.svg';
import { AuthContext } from '../provider/AuthContext';
import { ReactComponent as DashboardIcon } from '../images/dashboard-icon.svg';
import { ReactComponent as GozealZLogo } from '../images/gozeal-z-logo.svg';
import { ReactComponent as LandingIcon } from '../images/landing-icon.svg';
import { ReactComponent as MasterDataIcon } from '../images/master-data.svg';
import { ReactComponent as SettingsIcon } from '../images/settings-icon2.svg';
import classNames from 'classnames';
import routes from '../routes/routes.json';

interface typePageLayoutProps {
    children: React.ReactNode;
}

const ContentLayout = (props: typePageLayoutProps) => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const currentLocation = useLocation();

    useEffect(() => {
        if (user?.isClient) {
            navigate(routes['landing']['dataIngestion']);
        }
    }, [user, navigate]);

    const navItems = {
        dashboard: {
            to: routes['dashboard'],
            icon: <DashboardIcon width="26" height="26" />,
            label: 'Dashboard',
            index: routes['dashboard'],
        },
        lossRuns: {
            to: routes['lossRuns']['allLosses'],
            icon: <AllLossIcon width="26" height="26" />,
            label: 'Loss Runs',
            index: routes['lossRuns']['index'],
        },
        landing: {
            to: routes['landing']['dataIngestion'],
            icon: <LandingIcon width="26" height="26" />,
            label: 'Landing',
            index: routes['landing']['index'],
        },
        masterData: {
            to: routes['masterData']['account'],
            icon: <MasterDataIcon width="26" height="26" />,
            label: 'Master Data',
            index: routes['masterData']['index'],
        },
        settings: {
            to: routes['setting']['preprocessing'],
            icon: <SettingsIcon width="26" height="26" />,
            label: 'Settings',
            index: routes['setting']['index'],
        },
    };

    const getRoleBasedNavItems = () => {
        if (user?.role) {
            switch (user.role) {
                case READER:
                    return [navItems['dashboard']];
                case EXTERNAL_USER:
                    return [navItems['landing']];
                case QA:
                    return [
                        navItems['dashboard'],
                        navItems['lossRuns'],
                        navItems['landing'],
                    ];
                case DATA_CONTRIBUTOR:
                    return [
                        navItems['dashboard'],
                        navItems['lossRuns'],
                        navItems['landing'],
                        navItems['masterData'],
                        navItems['settings'],
                    ];
                default:
                    return [];
            }
        }
        return [];
    };

    const renderNavItems = () => {
        const navItems = getRoleBasedNavItems();
        return navItems.map((item, index) => {
            return (
                <NavLink
                    key={`navlink-${index}`}
                    to={item.to}
                    className={classNames(
                        'nav-link gz-nav-btn',
                        currentLocation.pathname.includes(item.index) ? 'active' : '',
                    )}
                >
                    {item.icon}
                    <span>{item.label}</span>
                </NavLink>
            );
        });
    };

    return (
        <div className="content-layout">
            <div id="navigation-panel">
                <div className="navigation-panel-container">
                    <div className="nav flex-column nav-pills navigation-item-container">
                        <div className="gz-logo flex-column align-items-center">
                            <a className="navbar-brand" href="/">
                                <GozealZLogo fill="#7F7FF0" width={32} height={32} />
                            </a>
                            <span className="pt-1">ZealDoc AI</span>
                        </div>
                        <div className="navigation-item-wrapper gz-scroll">
                            {!user?.isClient && renderNavItems()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="panel-content" id="panel-content">
                {props.children}
            </div>
        </div>
    );
};

export const PageLayout = (props: typePageLayoutProps) => {
    return <ContentLayout>{props.children}</ContentLayout>;
};
