import { QA_SHEET_DOWNLOAD_NAME, SUPPORTED_FILE_FORAMTS } from '../constants/constants';
import React, { useEffect, useRef, useState } from 'react';
import Checkbox from '../components/Checkbox';
import { ReactComponent as DownloadIcon } from '../images/download.svg';
import { ReactComponent as RefreshIcon } from '../images/refresh.svg';
import apiClient from '../api/base';
import classnames from 'classnames';
import { formatFileSize } from '../helper';
import saveAs from 'file-saver';
import { toast } from 'react-toastify';

interface fileDataType {
    originalFileName: string;
    modifiedOn: Date;
    uploadedOn: Date;
    uploader: string;
    uploaderEmail: string;
    fileSize: string;
}

export default function DataIngestionClient() {
    const [documentType, setDocumentType] = useState<string>('ACORD Forms');
    const allowedFormats = ['pdf'];
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileStatusData, setFileStatusData] = useState<fileDataType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshLoading, setRefreshLoading] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [sectionSelected, setSectionSelected] = useState<string>('uploaded');
    const [downloading, setDownloading] = useState<boolean>(false);

    const sections = [
        { key: 'uploaded', label: 'Uploaded Files' },
        { key: 'output', label: 'Output Files' },
        { key: 'qa', label: 'QA Status' },
    ];

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setSelectAll(isChecked);
        if (isChecked) {
            setSelectedFiles(
                fileStatusData.map((file) => {
                    return file.originalFileName;
                }),
            );
        } else {
            setSelectedFiles([]);
        }
    };

    const handleSelectFile = (name: string) => {
        setSelectedFiles((prevSelectedFiles) => {
            if (prevSelectedFiles.includes(name)) {
                return prevSelectedFiles.filter((i) => {
                    return i !== name;
                });
            }
            return [...prevSelectedFiles, name];
        });
    };

    const getFileDetailsApi = async (invokeType?: 'refresh') => {
        try {
            setLoading(true);
            if (invokeType === 'refresh') {
                setRefreshLoading(true);
            }
            const { data } = await apiClient.get(
                `v1/landing/${sectionSelected === 'uploaded' ? 'input-files' : 'qa-complete-files'}`,
            );
            const dataFormatted = data.data.map((file: any) => {
                return {
                    originalFileName: file['file_name'],
                    modifiedOn: file['modified_on'],
                    fileSize: file['file_size'],
                    uploadedOn: file['uploaded_on'],
                    uploader: file['uploaded_by_name'],
                    uploaderEmail: file['uploaded_by_email'],
                } as fileDataType;
            });
            setFileStatusData(dataFormatted);
            setLoading(false);
            if (invokeType === 'refresh') {
                setRefreshLoading(false);
            }
            // eslint-disable-next-line tseslint/no-unused-vars
        } catch (_error: any) {
            setLoading(false);
            if (invokeType === 'refresh') {
                setRefreshLoading(false);
            }
        }
    };

    const handleDownload = async () => {
        try {
            if (selectedFiles.length === 0) {
                toast.error('Please select files to download');
                return;
            }
            setDownloading(true);
            const res = await toast.promise(
                apiClient.post('v1/landing/download-blobs', selectedFiles, {
                    responseType: 'blob',
                }),
                {
                    pending: 'Downloading files, please wait...',
                    success: 'Files downloaded successfully',
                    error: 'Failed to download files',
                },
            );
            const blob = new Blob([res.data], { type: 'application/zip' });
            const date = new Date();
            saveAs(
                blob,
                `OutputFiles-${date.toLocaleDateString()}-${date.toLocaleTimeString()}.zip`,
                { autoBom: true },
            );
            setSelectedFiles([]);
            setTimeout(() => {
                return setDownloading(false);
            }, 2000);
            // eslint-disable-next-line tseslint/no-unused-vars
        } catch (_error: any) {
            setSelectedFiles([]);
            setDownloading(false);
        }
    };

    useEffect(() => {
        if (selectedFiles.length === fileStatusData.length && fileStatusData.length > 0) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedFiles, fileStatusData.length]);

    const validateFiles = (files: File[]) => {
        return files.every((file) => {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            return allowedFormats.includes(fileExtension || '');
        });
    };

    const handleUpload = async (files: File[]) => {
        if (files.length === 0) {
            toast.error('Please select files to upload');
            return;
        }

        const uploadFile = (file: File): Promise<any> => {
            const formData = new FormData();
            formData.append('files', file);

            return apiClient.post(`v1/landing/upload-blobs`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        };

        try {
            const uploadPromises = files.map((file) => {
                return uploadFile(file);
            });
            const responses = await toast.promise(Promise.all(uploadPromises), {
                pending: `Uploading ${files.length} file(s), please wait...`,
            });

            const allSuccessful = responses.every((res) => {
                return res.status === 201;
            });
            if (allSuccessful) {
                toast.success('Files uploaded successfully');
                getFileDetailsApi();
                setSelectedFiles([]);
            } else {
                toast.error('Some files failed to upload');
            }
        } catch (error: any) {
            toast.error(`An error occurred during upload: ${error.message}`);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const filesArray = Array.from(event.target.files);
            if (validateFiles(filesArray)) {
                handleUpload(filesArray);
            } else {
                toast.error('Invalid file format. Only PDF files are allowed.');
            }
        }
    };

    const handleSectionClick = (section: string) => {
        setSectionSelected(section);
    };

    const handleDownloadQASheet = async () => {
        try {
            const res = await apiClient.get('v1/landing/download-qa-blob', {
                responseType: 'blob',
            });
            const blob = new Blob([res.data], { type: 'application/octet-stream' });
            saveAs(blob, QA_SHEET_DOWNLOAD_NAME, { autoBom: true });
            // eslint-disable-next-line tseslint/no-unused-vars
        } catch (_error: any) {
            toast.error('Error downloading QA sheet');
        }
    };

    const handleClickUpload = () => {
        fileInputRef.current!.value = '';
        fileInputRef.current?.click();
    };

    useEffect(() => {
        setSelectAll(false);
        setSelectedFiles([]);
        getFileDetailsApi();
    }, [sectionSelected]);

    return (
        <div className="data-ingestion-container">
            <div className="select-container">
                <select
                    disabled={true}
                    value={documentType}
                    onChange={(e) => {
                        return setDocumentType(e.target.value);
                    }}
                    className="form-select select-data-ingestion-client"
                >
                    <option value="ACORD Forms">ACORD Forms</option>
                </select>
                {/* Button to open file picker */}
                <button onClick={handleClickUpload} className="gz-btn-primary-violet">
                    Upload Files
                </button>
                {/* Hidden file input field */}
                <input
                    className="invisible-component"
                    ref={fileInputRef}
                    type="file"
                    multiple
                    accept={`${SUPPORTED_FILE_FORAMTS.join(',')}`}
                    onChange={handleFileChange}
                />
            </div>
            <div className="data-ingestion-selection-action-container">
                <div className="data-ingestion-selection-container">
                    {sections.map((section) => {
                        return (
                            <span
                                key={section.key}
                                className={`data-ingestion-selection-item ${sectionSelected === section.key ? 'selected' : ''}`}
                                onClick={() => {
                                    return handleSectionClick(section.key);
                                }}
                            >
                                {section.label}
                            </span>
                        );
                    })}
                </div>
                {sectionSelected !== 'qa' ? (
                    <div className="data-ingestion-action-container">
                        <span
                            className={classnames(
                                'data-ingestion-action',
                                refreshLoading ? 'disabled' : '',
                            )}
                            onClick={() => {
                                if (!refreshLoading) {
                                    getFileDetailsApi('refresh');
                                }
                            }}
                        >
                            <RefreshIcon
                                className={classnames(
                                    refreshLoading ? 'animate-icon' : '',
                                )}
                                width="11px"
                                height="11px"
                            />{' '}
                            Refresh
                        </span>
                        <span
                            onClick={
                                sectionSelected === 'output'
                                    ? handleDownload
                                    : () => {
                                          return null;
                                      }
                            }
                            className={classnames(
                                'data-ingestion-action',
                                sectionSelected !== 'output' ? 'disabled' : '',
                            )}
                        >
                            <DownloadIcon
                                className={classnames(downloading ? 'animate-arrow' : '')}
                                width="13px"
                                height="13px"
                            />
                            Download{' '}
                        </span>
                    </div>
                ) : null}
            </div>
            {sectionSelected === 'qa' ? (
                <span
                    onClick={handleDownloadQASheet}
                    className="data-ingestion-client-link"
                >
                    Click this link to download QA sheet
                </span>
            ) : (
                <div className="file-status-container">
                    <table className="file-line-item-container">
                        <thead>
                            <tr className="file-header">
                                <td
                                    className={
                                        sectionSelected !== 'output'
                                            ? 'checkbox-cell-disabled'
                                            : ''
                                    }
                                >
                                    <Checkbox
                                        checked={{
                                            id: 'selectAll',
                                            value: selectAll,
                                        }}
                                        onChange={handleSelectAll}
                                        disabled={sectionSelected !== 'output'}
                                    />
                                </td>
                                <td className="file-header-item w-50">File Name</td>
                                <td className="file-header-item">Uploader</td>
                                <td className="file-header-item">Uploader Email</td>
                                <td className="file-header-item">Uploaded On</td>
                                <td className="file-header-item">File Size</td>
                            </tr>
                        </thead>
                        {loading ? (
                            <tbody>
                                <tr>
                                    <td colSpan={6} className="text-center">
                                        <div className="inline-spinner"></div>
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                                {fileStatusData.map((file, index) => {
                                    return (
                                        <tr key={index} className="file-body">
                                            <td
                                                className={
                                                    sectionSelected !== 'output'
                                                        ? 'checkbox-cell-disabled'
                                                        : ''
                                                }
                                            >
                                                <Checkbox
                                                    checked={{
                                                        id: `file-${index}`,
                                                        value: selectedFiles.includes(
                                                            file.originalFileName,
                                                        ),
                                                    }}
                                                    onChange={() => {
                                                        return handleSelectFile(
                                                            file.originalFileName,
                                                        );
                                                    }}
                                                    disabled={
                                                        sectionSelected !== 'output'
                                                    }
                                                />
                                            </td>
                                            <td className="file-body-item w-50">
                                                {file.originalFileName}
                                            </td>
                                            <td className="file-body-item">
                                                {file.uploader}
                                            </td>
                                            <td className="file-body-item">
                                                {file.uploaderEmail}
                                            </td>
                                            <td className="file-body-item">
                                                {new Date(
                                                    file.uploadedOn,
                                                ).toLocaleString()}
                                            </td>
                                            <td className="file-body-item">
                                                {formatFileSize(file.fileSize)}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </table>
                </div>
            )}
        </div>
    );
}
