import axios from 'axios';
import { getEnvironmentVariable } from '../helper';

const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop()?.split(';').shift() || null;
    }
    return null;
};

const apiClient = axios.create({
    baseURL: getEnvironmentVariable('REACT_APP_AZURE_API_ENDPOINT'),
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

apiClient.interceptors.request.use(
    (config) => {
        const csrfToken = getCookie('csrf_token');
        if (
            csrfToken &&
            ['post', 'put', 'delete', 'patch'].includes(config.method || '')
        ) {
            config.headers['X-CSRF-Token'] = csrfToken;
        }
        return config;
    },
    (error) => {
        return `Error getting token ${error}`;
    },
);

export default apiClient;

export async function getData(url: string) {
    const response: { data: any; error: string } = { data: {}, error: '' };
    await apiClient
        .get(url)
        .then((res) => {
            if (res.status === 200) {
                response['data'] = res.data;
            }
        })
        .catch((error) => {
            response.error = error.response.data.message;
        });
    return response;
}

export async function putData(url: string, data: any) {
    const response: { data: any; error: string } = { data: {}, error: '' };
    await apiClient
        .put(url, data)
        .then((res) => {
            if (res.status === 201) {
                response['data'] = res.data;
            }
        })
        .catch((error) => {
            response.error = error.response.data.message;
        });
    return response;
}

export async function postData(url: string, data: any) {
    const response: { data: any; error: string } = { data: {}, error: '' };
    await apiClient
        .post(url, data)
        .then((res) => {
            if (res.status === 201) {
                response['data'] = res.data;
            }
        })
        .catch((error) => {
            response.error = error.response.data.message;
        });
    return response;
}
