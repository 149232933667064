import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { REACT_APP_LOGIN_ENDPOINT } from '../constants/protectedResources';
import apiClient from '../api/base';
import { toast } from 'react-toastify';

export interface userType {
    id: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    isActive: boolean;
    lastLoggedAt: string;
    isClient: boolean;
    role: string;
}

interface authContextType {
    user: userType | null;
    loading: boolean;
    logoutLoading: boolean;
    isAuthenticated: boolean;
    login: () => void;
    logout: () => Promise<void>;
}

export const AuthContext = createContext<authContextType>({
    user: null,
    loading: true,
    logoutLoading: false,
    isAuthenticated: false,
    login: () => {
        return null;
    },
    logout: async () => {
        /* Do nothing */
    },
});

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<userType | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [logoutLoading, setLogoutLoading] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const getLoggedInUser = async () => {
        try {
            const response = await apiClient.get('v1/users/me');
            if (response.status === 200) {
                setUser({
                    id: response.data.id,
                    emailAddress: response.data.email,
                    firstName: response.data.first_name,
                    lastName: response.data.last_name,
                    isActive: response.data.is_active,
                    lastLoggedAt: response.data.last_logged_at,
                    isClient: response.data.is_client,
                    role: response.data.role,
                });
                setIsAuthenticated(true);
            }
            // eslint-disable-next-line tseslint/no-unused-vars
        } catch (_error: any) {
            setUser(null);
            setIsAuthenticated(false);
            // Toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getLoggedInUser();
    }, []);

    const login = () => {
        window.location.href = REACT_APP_LOGIN_ENDPOINT;
    };

    const logout = async () => {
        try {
            setLogoutLoading(true);
            const response = await apiClient.post('v1/auth/logout');
            if (response.status === 204) {
                setUser(null);
                setIsAuthenticated(false);
                window.location.href = '/';
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLogoutLoading(false);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                isAuthenticated,
                loading,
                logoutLoading,
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
