import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import React from 'react';
import { preprocessingLogsType } from './PreprocessingLogs';

export interface preprocessingLogDetailProps {
    data: preprocessingLogsType[];
    showModal: boolean;
    handleClose: () => void;
}

function PreprocessingLogDetail(props: preprocessingLogDetailProps) {
    const { data, showModal, handleClose } = props;
    const dataFormatted = data.map((eachData) => {
        return {
            Action: eachData.action,
            Description: eachData.description,
            Status: eachData.status,
            'Source Folder': eachData.sourceContainer,
            'Destination Folder': eachData.destinationContainer,
            'Uploaded By': eachData.uploadedByEmail,
            'Uploaded On': eachData.uploadedOn,
        };
    });
    return (
        <Modal show={showModal} onHide={handleClose} size="xl" centered>
            <ModalHeader closeButton className="modal-heading">
                Log Detail For {data.length ? data[0].originalFileName : ''}
            </ModalHeader>
            <ModalBody
                className="gz-scroll p-0"
                style={{ maxHeight: '70vh', overflow: 'scroll' }}
            >
                <div className="log-content-container">
                    <div className="file-header sticky">
                        <div className="d-flex align-items-center justify-content-between">
                            {dataFormatted.length
                                ? Object.keys(dataFormatted[0]).map((key, index) => {
                                      return (
                                          <span
                                              key={`key-${key}-index${index}`}
                                              className="w-40 mx-2"
                                          >
                                              {key}
                                          </span>
                                      );
                                  })
                                : null}
                        </div>
                    </div>
                    {dataFormatted.map((eachData, rowIndex) => {
                        return (
                            <div
                                key={`row-index${rowIndex}`}
                                className="d-flex justify-content-between text-break p-2"
                                style={{ borderBottom: '1px solid #ccc' }}
                            >
                                {Object.values(eachData).map((key, index) => {
                                    return (
                                        <span
                                            className="w-40 mx-2"
                                            key={`row${rowIndex}-col-index${index}`}
                                        >
                                            {key}
                                        </span>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </ModalBody>
        </Modal>
    );
}

export default PreprocessingLogDetail;
