import '../styles/pagination.scss';
import { ReactComponent as Arrow } from '../images/link-go-icon.svg';
import React from 'react';
import classnames from 'classnames';
import { usePagination } from '../custom-hooks/usePagination';

export interface paginationProps {
    totalRecords: number;
    recordLimitPerPage: number;
    siblingCount: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    tabStyle: string;
}

const ZealDocPagination = (props: paginationProps) => {
    const {
        recordLimitPerPage,
        totalRecords,
        siblingCount = 1,
        currentPage,
        onPageChange,
    } = props;
    const paginationRange = usePagination(
        totalRecords,
        recordLimitPerPage,
        siblingCount,
        currentPage,
    );

    if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const onPageChangeHandler = (page: number) => {
        onPageChange(page);
    };

    const getFillColor = () => {
        if (props.tabStyle.includes('project') || props.tabStyle.includes('template')) {
            return '#7F7FF0';
        }
        if (props.tabStyle.includes('task')) {
            return '#4ECD62';
        }
        if (props.tabStyle.includes('milestone')) {
            return '#E2798F';
        }

        return '';
    };

    const lastPage = paginationRange && paginationRange[paginationRange.length - 1];

    return (
        <ul className="pagination-container">
            <li
                className="pagination-item me-auto"
                style={{ cursor: 'default' }}
            >{`Showing ${currentPage === 1 ? currentPage : (currentPage - 1) * recordLimitPerPage + 1} to ${currentPage * recordLimitPerPage} of ${totalRecords} items`}</li>
            <li
                onClick={onPrevious}
                className={classnames('pagination-item leftarrow', props.tabStyle, {
                    disabled: currentPage === 1,
                })}
            >
                <Arrow width="12px" height="12px" fill={getFillColor()}></Arrow>
            </li>
            {paginationRange &&
                paginationRange.map((pageNumber, index) => {
                    if (typeof pageNumber === 'number') {
                        return (
                            <li
                                key={`page${pageNumber}-index${index}`}
                                onClick={() => {
                                    return onPageChangeHandler(pageNumber);
                                }}
                                className={classnames('pagination-item', props.tabStyle, {
                                    selected: pageNumber === currentPage,
                                })}
                            >
                                {pageNumber}
                            </li>
                        );
                    }
                    return (
                        <li
                            key={`page${pageNumber}-index${index}`}
                            className="pagination-item dots"
                        >
                            {pageNumber}
                        </li>
                    );
                })}
            <li
                onClick={onNext}
                className={classnames('pagination-item rightarrow', props.tabStyle, {
                    disabled: currentPage === lastPage,
                })}
            >
                <Arrow width="12px" height="12px" fill={getFillColor()}></Arrow>
            </li>
        </ul>
    );
};

export default ZealDocPagination;
